import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/disclaimer/relation.png'


import { ActionSheet } from "vant";
import { ref } from "vue";


const __default__ = {
  name: "DisclaimerPop"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const DEFAULT_TEXT = "免责声明";
const company = ref<string>(APP_CONFIG_COMPANY_NAME);

const visible = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "disclaimer-pop",
    onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"])),
    onTouch: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
    onTouchend: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"])),
    onTouchstart: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"])),
    onTouchcancel: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createElementVNode("p", {
      class: "disclaimer-link",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (visible.value = true), ["stop"]))
    }, _toDisplayString(DEFAULT_TEXT)),
    _createVNode(_unref(ActionSheet), {
      teleport: "body",
      "lock-scroll": false,
      show: visible.value,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((visible).value = $event)),
      title: DEFAULT_TEXT
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "disclaimer-container",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (visible.value = false))
        }, [
          _cache[24] || (_cache[24] = _createElementVNode("h1", null, [
            _createElementVNode("strong", null, "免责声明")
          ], -1)),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, "一、本网站所提供的各项服务的所有权和运作权归" + _toDisplayString(company.value) + "所有。您在使用本网站前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用，但如果您使用，您的使用行为将被视为对本声明全部内容的认可。", 1),
            _cache[12] || (_cache[12] = _createElementVNode("li", null, [
              _createTextVNode(" 二、您在从事与本网站相关的所有行为(包括但不限于访问浏览、利用、转载、宣传介绍)时，必须以善意且谨慎的态度行事；访问者不得故意或者过失的损害或者弱化本网站的各类合法权利与利益，不得利用本网站以任何方式直接或者间接的从事违反中国法律、国际公约以及社会公德的行为，且访问者应当恪守下述承诺： "),
              _createElementVNode("ul", null, [
                _createElementVNode("li", null, "1.传输和利用信息符合中国法律、国际公约的规定、符合公序良俗;"),
                _createElementVNode("li", null, "2.不将本网站以及与之相关的网络服务用作非法用途以及非正当用途;"),
                _createElementVNode("li", null, "3.不干扰和扰乱本网站以及与之相关的网络服务;"),
                _createElementVNode("li", null, "4.遵守与本网站以及与之相关的网络服务的协议、规定、程序和惯例等。")
              ])
            ], -1)),
            _cache[13] || (_cache[13] = _createElementVNode("li", null, "三、互联网传输可能会受到干扰，中断、延迟或数据错误，本网站对于非本网站能控制的通讯设施故障可能引致的数据及交易之准确性或及时性不负任何责任。不保证网站服务器及网络的稳定性，不保证本网站在任何时候均可供浏览、阅读和使用。", -1)),
            _cache[14] || (_cache[14] = _createElementVNode("li", null, "四、本网站对于您在进入、浏览和使用本网站或从本网站下载任何内容而导致病毒或其他破坏性程序对您的电脑系统以及其他任何软件、硬件、IT系或财产的损害或损失不承担任何责任。本网站对于第三人采用非法手段进入本网站取得有关密码、资料和内容而造成的任何损害或损失不承担任何责任。", -1)),
            _cache[15] || (_cache[15] = _createElementVNode("li", null, "五、任何在本网站出现的信息包括但不限于评论、预测、图表、指标、理论、直接的或暗示的指示均只作为参考，您须对任何自主决定的行为负责。网站内容仅代表原作者本人的观点，不代表本网站的观点和看法，与本网站立场无关。", -1)),
            _cache[16] || (_cache[16] = _createElementVNode("li", null, "六、请自行妥善保管您的账号及密码，如您未保管好您的账号及密码而对您、本网站或第三方造成损害或损失，您将自行承担全部责任。", -1)),
            _cache[17] || (_cache[17] = _createElementVNode("li", null, "七、本网站对于您所使用的各项服务、或无法使用各项服务所导致的任何直接、间接、衍生或特别损害，不负任何赔偿责任。", -1)),
            _cache[18] || (_cache[18] = _createElementVNode("li", null, "八、当政府部门、司法机关等依照法定程序要求本网站披露个人资料时，本网站将根据执法单位之要求或为公共安全之目的提供个人资料。在此情况下之任何披露，本网站均得免责。", -1)),
            _cache[19] || (_cache[19] = _createElementVNode("li", null, "九、本网站所刊载的各类形式(包括但不仅限于文字、图片、图表)的作品仅供参考使用，并不代表本网站同意其说法或描述，仅为提供更多信息，也不构成任何投资建议。对于访问者根据本网站提供的信息所做出的一切行为，除非另有明确的书面承诺文件，否则本网站不承担任何形式的责任。", -1)),
            _cache[20] || (_cache[20] = _createElementVNode("li", null, "十、当本网站以链接形式推荐其他网站内容时，本网站并不对这些网站或资源的可用性负责，且不保证从这些网站获取的任何内容、产品、服务或其他材料的真实性、合法性，对于任何因使用或信赖从此类网站或资源上获取的内容、产品、服务或其他材料而造成(或声称造成)的任何直接或间接损失，本网站均不承担任何责任。", -1)),
            _cache[21] || (_cache[21] = _createElementVNode("li", null, "十一、本网站包含与第三者网站的联系。本网站不对任何连结的站点的内容负责。本网站提供连接只作为对用户的一种便利。为了您的方便，本网站向您提供此链接，该链接会使您离开本网站。由于所链接的网站不在本网站的控制范围内。所以，对于任何所链接的网站上的内容或某一链接的网站所含的任何链接或对该等网站的任何变动或更新，本网站不负责任。本网站对从所链接的网站收到的网络传送或任何其它形式的传输不承担任何责任。", -1)),
            _cache[22] || (_cache[22] = _createElementVNode("li", null, "十二、本网站所收集的部分公开资料来源于互联网，转载的目的在于传递更多信息及用于网络分享，并不代表本站赞同其观点和对其真实性负责，也不构成任何其他建议。本站部分作品是由网友自主投稿和发布、编辑整理上传，对此类作品本站仅提供交流平台，不为其版权负责。如果您发现网站上有侵犯您的知识产权的作品，请与我们取得联系，我们会及时修改或删除。", -1)),
            _cache[23] || (_cache[23] = _createElementVNode("li", null, "十三、网站有权不经提前通知随时修改本协议。本协议一经变动，本网站将在相关页面提示修改内容，用户如果不同意本协议的修改，可以放弃使用或访问本网站或取消已经获得的服务，如果用户选择在本协议修改后继续使用本网站，则视为用户已经接受本协议的修改。", -1)),
            _createElementVNode("li", null, [
              _createElementVNode("p", null, "以上声明内容的最终解释权归" + _toDisplayString(company.value) + "所有。", 1),
              _createElementVNode("p", null, _toDisplayString(company.value) + "联系方式：", 1),
              _cache[8] || (_cache[8] = _createElementVNode("img", {
                src: _imports_0,
                alt: ""
              }, null, -1)),
              _cache[9] || (_cache[9] = _createElementVNode("p", null, "发布日期：2023-09-17", -1)),
              _cache[10] || (_cache[10] = _createElementVNode("p", null, "更新日期：2023-09-17", -1)),
              _cache[11] || (_cache[11] = _createElementVNode("p", null, "生效日期：2023-09-17", -1))
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ], 32))
}
}

})