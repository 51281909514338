import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toHandlerKey as _toHandlerKey, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/guide_new/card_winter_001.png'
import _imports_1 from '@/assets/images/guide_new/card_winter_002.png'
import _imports_2 from '@/assets/images/guide_new/card_winter_003.png'
import _imports_3 from '@/assets/images/guide_new/card_winter_004.png'
import _imports_4 from '@/assets/images/guide_new/card_winter_005.png'
import _imports_5 from '@/assets/images/guide_new/card_winter_006.png'
import _imports_6 from '@/assets/images/guide_new/finger.png'
import _imports_7 from '@/assets/images/guide_new/book.png'


const _hoisted_1 = { class: "native-box" }
const _hoisted_2 = { class: "indicator-box" }
const _hoisted_3 = { class: "icp-box" }

import Icp from "@/components/common/Icp.vue";
import { Swipe, SwipeItem } from 'vant';

import router from "@/router";
import { useMyStrategy } from "@/hooks/useMyStrategy";
import { Haa, OeReportType, OeReportSubType } from "@haa/fwk-h5/lib/index";
import { ref, watch } from "vue";

// sdk监听生命周期
enum EventNames {
  Touchstart = "touchstart",
  Click = "click"
}

/** 默认使用touchstart触发点击 */

export default /*@__PURE__*/_defineComponent({
  __name: 'GuideViewNew',
  setup(__props) {

Haa.util().useLifeCycle();

let enterTimer: number | null = null;

const clearTimer = () => {
  if (enterTimer != null) {
    clearTimeout(enterTimer);
    enterTimer = null;
  }
};

const { strategy } = useMyStrategy();
const { oeReport, openWindow } = Haa.util().useOeReport();

// 全屏点击
const onFullScreenClick = (e: Event) => {
  e?.stopPropagation?.();

  if (strategy.value?.guide_click_enable === 0) return;

  if (strategy.value?.guide_click_enable === 1) {
    onRouterEnter("FullScreen", e);
  }
};

// 跳转函数
const onRouterEnter = (msgType: string, e?: any) => {
  e?.stopPropagation?.();

  let appX, appY;

  if (e) {
    appX = e.touches ? e.touches[0].clientX : e.clientX;
    appY = e.touches ? e.touches[0].clientY : e.clientY;
    appX = Math.round(appX);
    appY = Math.round(appY);
  }

  oeReport(OeReportType.Action, OeReportSubType.BtnClick, "Guide", msgType, undefined, appX, appY);

  const result = openWindow({ skip: '1' });

  if (!!result) return;

  router.replace({
    path: "/home",
  });

  clearTimer();
};

// 31038上报处理函数
const onCriticalClick = (e: TouchEvent) => {
  let appX, appY;

  if (e) {
    appX = e.touches[0].clientX;
    appY = e.touches[0].clientY;
    appX = Math.round(appX);
    appY = Math.round(appY);
  }

  oeReport(
    OeReportType.Action,
    OeReportSubType.NonCriticalAreaClick,
    "Guide",
    "NonCriticalAreaClick",
    undefined,
    appX,
    appY
  );
};

/** 处理策略控制事件处理方式 -----------------begin */
let hotEventName = ref(EventNames.Touchstart);
let notHotEventName = ref(EventNames.Touchstart);

const handleEventName = (type: number) => {
  // 0：全部touch_down触发跳转(默认);
  // 1：热点区域click触发，非热点区域touch_down触发跳转；
  // 2：热点区域touch_down触发，非热点区域click触发跳转;
  // 3：全部click触发跳转；1：热点区域click触发;
  const handleMap = [
    [EventNames.Touchstart, EventNames.Touchstart],
    [EventNames.Click, EventNames.Touchstart],
    [EventNames.Touchstart, EventNames.Click],
    [EventNames.Click, EventNames.Click]
  ];

  const [_hotEventName, _notHotEventName] = handleMap[type] ?? [];

  _hotEventName && (hotEventName.value = _hotEventName);
  _notHotEventName && (notHotEventName.value = _notHotEventName);
};
/** 处理策略控制事件处理方式 -----------------end */

/** 监听策略返回情况 */
watch(
  () => strategy.value,
  (res) => {
    if (!res) return;

    // 根据策略 是否倒计时离开引导页
    if (res.guide_timeout !== -1) {
      enterTimer = setTimeout(() => {
        onRouterEnter("Timerout_" + res.guide_timeout + "s");
      }, res.guide_timeout * 1000);
    }

    // 根据策略 判断是否指定触发事件类型
    handleEventName(res.guide_used_click);
  },
  {
    immediate: true
  }
);

return (_ctx: any,_cache: any) => {
  const _component_WapNative = _resolveComponent("WapNative")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "guide-view" }, { [_toHandlerKey(_unref(notHotEventName))]: onFullScreenClick }), [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_WapNative, { "space-key": "GuideNative" })
    ]),
    _createElementVNode("div", _mergeProps({ class: "card-box" }, {
      [_toHandlerKey(_unref(hotEventName))]: _cache[0] || (_cache[0] = ($event: any) => (onRouterEnter('CardBtn', $event)))
    }, {
      onTouchstart: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
      onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
    }), [
      _createVNode(_unref(Swipe), {
        autoplay: 1200,
        "indicator-color": "#fff"
      }, {
        indicator: _withCtx(({ active, total }) => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass(["custom-indicator", active == 0 ? 'active' : ''])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(["custom-indicator", active == 1 ? 'active' : ''])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(["custom-indicator", active == 2 ? 'active' : ''])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(["custom-indicator", active == 3 ? 'active' : ''])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(["custom-indicator", active == 4 ? 'active' : ''])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(["custom-indicator", active == 5 ? 'active' : ''])
            }, null, 2)
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_unref(SwipeItem), null, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("img", { src: _imports_0 }, null, -1)
            ])),
            _: 1
          }),
          _createVNode(_unref(SwipeItem), null, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createElementVNode("img", { src: _imports_1 }, null, -1)
            ])),
            _: 1
          }),
          _createVNode(_unref(SwipeItem), null, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createElementVNode("img", { src: _imports_2 }, null, -1)
            ])),
            _: 1
          }),
          _createVNode(_unref(SwipeItem), null, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createElementVNode("img", { src: _imports_3 }, null, -1)
            ])),
            _: 1
          }),
          _createVNode(_unref(SwipeItem), null, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createElementVNode("img", { src: _imports_4 }, null, -1)
            ])),
            _: 1
          }),
          _createVNode(_unref(SwipeItem), null, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createElementVNode("img", { src: _imports_5 }, null, -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ], 16),
    _createElementVNode("div", _mergeProps({ class: "finger-box" }, {
      [_toHandlerKey(_unref(hotEventName))]: _cache[3] || (_cache[3] = ($event: any) => (onRouterEnter('FingerBtn', $event)))
    }, {
      onTouchstart: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
      onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
    }), _cache[12] || (_cache[12] = [
      _createElementVNode("img", { src: _imports_6 }, null, -1)
    ]), 16),
    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "shadow-box" }, [
      _createElementVNode("img", { src: _imports_7 })
    ], -1)),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(Icp)
    ])
  ], 16))
}
}

})