import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "home-view" }
const _hoisted_2 = { class: "tab-bar" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "tab-content" }

import { Haa, OeReportType, OeReportSubType } from "@haa/fwk-h5/lib/index";
// sdk监听生命周期
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

Haa.util().useLifeCycle();

const route = useRoute();
const tab = ref<TabItemType[]>([
    {
        title: '推荐',
        path: '/home',
        key: 'home',
        icon: require('@/assets/images/common/tab_1.png'),
        icon_s: require('@/assets/images/common/tab_1_s.png'),
    },
    {
        title: '热门',
        path: '/hot',
        key: 'hot',
        icon: require('@/assets/images/common/tab_2.png'),
        icon_s: require('@/assets/images/common/tab_2_s.png'),
    },
]);
const router = useRouter();
const onTabClick = (item: TabItemType) => {
    active.value = item.key;
    router.push(item.path);
}
const active = ref(tab.value.find(item => item.path === route.path)?.key);

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _directive_slide = _resolveDirective("slide")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "home-title" }, "养生知识", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tab.value, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["tab-bar-item", active.value == item.key && 'active']),
          onClick: ($event: any) => (onTabClick(item))
        }, [
          _createElementVNode("img", {
            src: active.value == item.key ? item.icon_s : item.icon
          }, null, 8, _hoisted_4),
          _createElementVNode("span", null, _toDisplayString(item.title), 1)
        ], 10, _hoisted_3))
      }), 256))
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
      _createVNode(_component_RouterView)
    ])), [
      [_directive_slide]
    ])
  ]))
}
}

})